<template>
  <!--
    The view for the OutroList-component.
  -->
  <div 
    v-if="introOutroId" 
    class="outroOverview"
  >
    <OutroList
      ref="outroList"
      :intro-outro-id="introOutroId"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :intro-outro-id="introOutroId"
      @reload="reloadOutroList"
    />
  </div>
</template>

<script>
export default {
  name: "OutroOverview",
  components: {
    OutroList: () => import('@/components/VideoPostprocessing/IntroOutro/OutroList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    introOutroId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
   methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadOutroList () {
      this.$refs.outroList.getIntroOutroAndCloseSidebar();
    }
  }
}
</script>
